body {
  padding: 10px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #3d4721;
  color: white;
  font-family: "Roboto", sans-serif;

  background-image: url(../public/zsu.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Reset */
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
img {
  display: block;
  max-width: 100%;
  height: auto;
}

button {
  display: block;
  cursor: pointer;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 6px; 
}

::-webkit-scrollbar-thumb {
  background-color: #dcdcdc; 
  border: none; 
  border-radius: 3px; 
}

::-webkit-scrollbar-track {
  background-color: transparent; 
}
